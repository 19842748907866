import { useAsyncAPI } from "~/uses/useMyFetch";

export const getProductsByLabel = async (label, sort = "random", fields) => {
  return useAsyncAPI("/catalog/product/list/label", {
    params: {
      label,
      "fields[]": fields,
      sort,
    },
    lazy: true,
  });
};
